import React, { lazy, useState } from 'react'

const Auftrags = lazy(() => import('../components/auftrags/index'))
const Accounts = lazy(() => import('../components/accounts/index'))
const Workshop = lazy(() => import('../components/workshops/index'))
const ShowAuftragDetails = lazy(() => import('../components/auftrags/ShowAuftagDetails'))
const WorkshopDetails = lazy(() => import('../components/workshops/WorkshopDetails'))
const Leads = lazy(() => import('../components/leads/index'))
const DashboardWrapper = lazy(() => import('../components/dashboard/DashboardWrapper'))
const ContactInfo = lazy(() => import('../components/contactInfo/index'))
const UploadCatalog = lazy(() => import('../components/uploadCatalog/UploadCatalog'))
const BrandGroups = lazy(() => import('../components/brand-groups/index'))
const WorkshopGroups = lazy(() => import('../components/workshop-groups/index'))
const WorkshopPermissions = lazy(() => import('../components/workshop-permissions/index'))
const BrandsAssociation = lazy(() => import('../components/brandsAssociation/index'))
const Roles = lazy(() => import('../components/roles/Roles'))
const DeclarationOfUse = lazy(() => import('../components/declaration_of_use/DeclarationOfUse'))

export const ROUTES = (props) => {
  const [initStaticState, setInitStaticState] = useState({ pageNo: 1 })
  const [initState, setInitState] = useState({ pageNo: 1, expandedRows: [], detailsData: [] })
  const [lastClickedRowId, setLastClickedRowId] = useState(null)

  return [
    {
      path: '/dashboard',
      key: 'DASHBOARD',
      exact: true,
      component: <DashboardWrapper
        dashboardActions={props?.actions?.dashboardActions}
        workshopAnalyticsData={props?.dashboardStateData?.analyticsWorkshopData}
        dealerAnalyticsData={props?.dashboardStateData?.analyticsDealerData}
        brandAnalyticsData={props?.dashboardStateData?.analyticsBrandData}
        workshopAccountsList={props?.dashboardStateData?.workshopAccountsList}
        workshopsList={props?.dashboardStateData?.workshopsList}
                 />
    },
    {
      path: '/auftrags',
      key: 'AUFTRAGS',
      exact: true,
      component: <Auftrags
        initStaticState={initStaticState}
        setInitStaticState={setInitStaticState}
        auftragActions={props.actions.auftragActions}
        auftragStateData={props.auftragStateData}
        lastClickedRowId={lastClickedRowId}
        setLastClickedRowId={setLastClickedRowId}
                 />
    },
    {
      path: '/auftrag/:id',
      key: 'AUFTRAGS',
      exact: true,
      component: <ShowAuftragDetails auftragActions={props.actions.auftragActions} auftragStateData={props.auftragStateData} />
    },
    {
      path: '/auftrags/:id',
      key: 'AUFTRAGS',
      exact: true,
      component: <Auftrags
        initStaticState={initStaticState}
        setInitStaticState={setInitStaticState}
        auftragActions={props.actions.auftragActions}
        auftragStateData={props.auftragStateData}
        lastClickedRowId={lastClickedRowId}
        setLastClickedRowId={setLastClickedRowId}
                 />
    },
    {
      path: '/workshop/:id/auftrags',
      key: 'WORKSHOP_AUFTRAGS',
      exact: true,
      component: <Auftrags
        initStaticState={initStaticState}
        setInitStaticState={setInitStaticState}
        auftragActions={props.actions.auftragActions}
        auftragStateData={props.auftragStateData}
        lastClickedRowId={lastClickedRowId}
        setLastClickedRowId={setLastClickedRowId}
                 />
    },
    {
      path: '/workshop-accounts',
      key: 'WORKSHOP',
      exact: true,
      component: <Workshop
        initStaticState={initState}
        setInitStaticState={setInitState}
        workshopActions={props.actions.workshopActions}
        workshopStateData={props.workshopStateData}
        accountStateData={props.accountStateData}
                 />
    },
    {
      path: '/workshops/:id',
      key: 'WORKSHOP',
      exact: true,
      component: <WorkshopDetails workshopActions={props.actions.workshopActions} workshopStateData={props.workshopStateData} />
    },
    {
      path: '/accounts',
      key: 'ACCOUNTS',
      exact: true,
      component: <Accounts accountActions={props.actions.accountActions} accountStateData={props.accountStateData} />
    },
    {
      path: '/leads',
      key: 'LEADS',
      exact: true,
      component: <Leads />
    },
    {
      path: '/brand-groups',
      key: 'BRAND_GROUPS',
      exact: true,
      component: <BrandGroups accountStateData={props.accountStateData} workshopActions={props.actions.workshopActions} workshopStateData={props.workshopStateData} />
    },
    {
      path: '/workshop-groups',
      key: 'WORKSHOP_GROUPS',
      exact: true,
      component: <WorkshopGroups accountStateData={props.accountStateData} workshopActions={props.actions.workshopActions} workshopStateData={props.workshopStateData} />
    },
    {
      path: '/workshop-permissions',
      key: 'WORKSHOP_PERMISSIONS',
      exact: true,
      component: <WorkshopPermissions accountStateData={props.accountStateData} workshopActions={props.actions.workshopActions} workshopStateData={props.workshopStateData} />
    },
    {
      path: '/contact-info',
      key: 'CONTACT_INO',
      exact: true,
      component: <ContactInfo contactInfoActions={props.actions.contactActions} contactStateData={props.contactInfoStateData} />
    },
    {
      path: '/upload-catalog',
      key: 'UPLOAD_CATALOG',
      exact: true,
      component: <UploadCatalog uploadCatalogActions={props.actions.uploadCatalogActions} uploadCatalogData={props.uploadCatalogData} />
    },
    {
      path: '/brands-association',
      key: 'BRANDS_ASSOCIATION',
      exact: true,
      component: <BrandsAssociation accountStateData={props.accountStateData} brandsAssociationActions={props.actions.brandsAssociationActions} brandsAssociationStateData={props.brandsAssociationStateData} />
    },
    {
      path: '/all-roles',
      key: 'ALL_ROLES',
      exact: true,
      component: <Roles rolesStateData={props.rolesStateData} roleActions={props.actions.rolesActions} />
    },
    {
      path: '/declaration-of-use',
      key: 'DECLARATION_OF_USE',
      exact: true,
      component: <DeclarationOfUse />
    },
    {
      path: '*',
      key: 'ROUTE_NOT_MATCHED',
      exact: true,
      component: <DashboardWrapper
        dashboardActions={props?.actions?.dashboardActions}
        workshopAnalyticsData={props?.dashboardStateData?.analyticsWorkshopData}
        dealerAnalyticsData={props?.dashboardStateData?.analyticsDealerData}
        brandAnalyticsData={props?.dashboardStateData?.analyticsBrandData}
        workshopAccountsList={props?.dashboardStateData?.workshopAccountsList}
        workshopsList={props?.dashboardStateData?.workshopsList}
                 />
    }
  ]
}
