import { SHOW_AUFTRAG_DATA, SHOW_CHANGE_LOG_DATA, UPDATE_AUFTRAG_QUANTITY, UPDATE_AUFTRAG_DATA, SHOW_EVENT_DATA, SHOW_TIMELINE_DATA, SHOW_AUFTRAG_DATA_BY_ID, DELETE_OE_PARTS, UPDATE_PARTS_DEALER, DELETE_COMPLETE_ORDER, UPDATE_VIN_PARTS_DEALER, UPDATE_PART_DEALER, UPDATE_STATUS, UPDATE_START_DATE, UPDATE_END_DATE, UPDATE_ORDER_TYPE } from '../constants/actionTypes'
import { GET_ALL_AUFTRAGS_API } from '../constants/endpoints'
import { SUCCESS_CODE, TOAST_ERROR } from '../constants/siteConstant'
import { hideLoader, showLoader, showToaster } from '../helper/Utils'
import { getData, patchData } from '../helper/apiCall'

export function showAuftragDetails (data) {
  return { type: SHOW_AUFTRAG_DATA, data }
}

export function showAuftragDetailsById (data) {
  return { type: SHOW_AUFTRAG_DATA_BY_ID, data }
}

export function updateAuftragQuantity (data) {
  return { type: UPDATE_AUFTRAG_QUANTITY, data }
}

export function deleteOeParts (data) {
  return { type: DELETE_OE_PARTS, data }
}

export function updatePartsDealer (data) {
  return { type: UPDATE_PARTS_DEALER, data }
}

export function updateAuftragData (data) {
  return { type: UPDATE_AUFTRAG_DATA, data }
}

export function updateVinPartsDealer (data) {
  return { type: UPDATE_VIN_PARTS_DEALER, data }
}

export function deleteCompleteOrder (id, data) {
  return { type: DELETE_COMPLETE_ORDER, id, data }
}

export function showTimelineData (data) {
  return { type: SHOW_TIMELINE_DATA, data }
}

export function showEventData (data) {
  return { type: SHOW_EVENT_DATA, data }
}

export function showChangelog (data) {
  return { type: SHOW_CHANGE_LOG_DATA, data }
}

export function updatePartDealer (data) {
  return { type: UPDATE_PART_DEALER, data }
}
export function updateStatus (data) {
  return { type: UPDATE_STATUS, data }
}
export function updateStartDate (data) {
  return { type: UPDATE_START_DATE, data }
}
export function updateEndDate (data) {
  return { type: UPDATE_END_DATE, data }
}
export function updateOrderType (data) {
  return { type: UPDATE_ORDER_TYPE, data }
}
export function tryFetchingAuftragData (pageNo, pageSize, sortBy, sortOrder, searchQuery, workshopId, selectedPartDealer, selectedStatus, startDate, endDate, orderType) {
  return function (dispatch) {
    showLoader()
    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }

    if (workshopId) {
      queryParams.workshopId = workshopId
    }
    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }

    if (selectedPartDealer) {
      queryParams.part_dealer = selectedPartDealer
    }

    if (selectedStatus?.length > 0) {
      queryParams.status = selectedStatus
    }

    if (startDate) {
      queryParams.start_date = startDate
    }

    if (endDate) {
      queryParams.end_date = endDate
    }

    if (orderType) {
      queryParams.insurance = orderType
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${GET_ALL_AUFTRAGS_API}?${queryString}`
      : GET_ALL_AUFTRAGS_API

    getData(endpointWithQuery)
      .then(response => {
        hideLoader()
        dispatch(showAuftragDetails(response))
      })
  }
}

export function tryFetchingAuftragDetails (auftragId) {
  return function (dispatch) {
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}`
    showLoader()
    getData(url)
      .then(response => {
        hideLoader()
        dispatch(showAuftragDetailsById(response))
      })
  }
}

export function tryFetchingAuftragEvents (auftragId) {
  return function (dispatch) {
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}/events`
    showLoader()
    getData(url)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(showTimelineData(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function trydeletingCompleteOrder (auftragId) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}/delete-auftrag`
    patchData(url)
      .then(response => {
        hideLoader()
        dispatch(deleteCompleteOrder(auftragId, response))
      })
  }
}

export function tryUpdatingAuftragOENumber (auftragId, data) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}/parts`

    patchData(url, { update: data })
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(showAuftragDetailsById(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryDeletingOeNumber (auftragId, data) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}/delete-oe-parts`
    patchData(url, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(deleteOeParts(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
/* eslint-disable */
export function tryUpdatingAuftrag (auftragId, data, number_of_parts = 0) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}`
    patchData(url, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updatePartsDealer(response))
          if (data?.status) {
            /* eslint-disable */
            dispatch(updateAuftragData(number_of_parts ? { ...response?.data, number_of_parts } : response?.data))
          }
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdateVinPartsDealer (auftragId, data) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}`
    patchData(url, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updateVinPartsDealer(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryFetchingChangeLog (auftragId) {
  return function (dispatch) {
    showLoader()
    const url = `${GET_ALL_AUFTRAGS_API}/${auftragId}/change-history`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(showChangelog(jsonResponse?.data))
        } else {
          showToaster({ message: (jsonResponse?.message || jsonResponse?.error), type: TOAST_ERROR })
        }
      })
  }
}
