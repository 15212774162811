import {
  SHOW_WORKSHOP_ACCOUNT_DATA,
  UPDATE_WORKSHOP_ACCOUNT,
  UPDATE_WORKSHOP_BY_ID_DATA,
  UPDATE_WORKSHOP_ACCOUNT_DETAILS,
  WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS,
  WORKSHOP_ACCOUNT_CREATED,
  WORKSHOP_ACCOUNT_CREATION_FAILED,
  UPDATE_ALL_DMS,
  UPDATE_WORKSHOP_USERS_DATA,
  WORKSHOP_UPDATE_IN_PROGRESS,
  WORKSHOP_UPDATE_COMPLETED,
  UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS,
  UPDATE_WORKSHOP_ADDRESS_COMPLETED,
  UPDATE_PART_DEALER_IN_PROGRESS,
  UPDATE_PART_DEALER_COMPLETED,
  UPDATE_SELECTED_ACCOUNT,
  WORKSHOP_USER_ADDED,
  UPDATE_WORKSHOP_USER,
  TOGGLE_PARTS_DEALER_STATUS,
  WORKSHOP_CREATED_FOR_ACCOUNT,
  ADMIN_USER_PREFERENCES,
  FETCH_ADMIN_PREFERENCES, ADD_NEW_BRAND_GROUP_TO_LIST, DELETE_BRAND_GROUP_FROM_LIST,
  UPDATE_DMS_IDS, UPDATE_BRAND_LIST, UPDATE_BRAND_GROUP_LIST, DELETE_WS_GROUP_FROM_LIST,
  UPDATE_WS_PERMISSION_LIST, UPDATE_CURRENT_WS, UPDATE_CURRENT_BRAND_GROUP, UPDATE_WS_MODES_LIST,
  UPDATE_WS_GROUP_LIST, ADD_NEW_PERMISSION_TO_LIST, ADD_NEW_WS_GROUP_TO_LIST,
  ADD_WORKSHOP_ACCOUNT_WITH_GROUP,
  REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP,
  UPDATE_CURRENT_PERMISSION_DATA,
  DELETE_PERMISSION_FROM_LIST
} from '../constants/actionTypes'
import { WORKSHOP_API, SYNC_ORDER_TYPE_API, WORKSHOP_ACCOUNTS_API, GET_ALL_DMS_API, PART_DEALERS_LISTING, ADMIN_USER_PREFERENCES_API, BRAND_LIST_API, BRAND_GROUPS_LIST_API, WS_PERMISSIONS_LIST_API, WS_PERMISSIONS_CREATE_API, WS_GROUPS_LIST_API, WS_GROUPS_CREATE_API, WORKSHOP_GROUP_ASSOC_API } from '../constants/endpoints'
import { SUCCESS_CODE, TOAST_ERROR, TOAST_SUCCESS } from '../constants/siteConstant'
import { hideLoader, showLoader, showToaster } from '../helper/Utils'
import { getData, postData, patchData, putData, deleteData } from '../helper/apiCall'

export function showWorkshopAccountData (data) {
  return { type: SHOW_WORKSHOP_ACCOUNT_DATA, data }
}

export function updateWorkshopAccountDetails (data) {
  return { type: UPDATE_WORKSHOP_ACCOUNT_DETAILS, data }
}

export function updateWorkshopAccounts (data) {
  return { type: UPDATE_WORKSHOP_ACCOUNT, data }
}

export function updateWorkshopData (data) {
  return { type: UPDATE_WORKSHOP_BY_ID_DATA, data }
}

export function workshopAccountCreationInProgress (data) {
  return { type: WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS, data }
}

export function workshopAccountCreated (data) {
  return { type: WORKSHOP_ACCOUNT_CREATED, data }
}

export function workshopCreatedForAccount (data) {
  return { type: WORKSHOP_CREATED_FOR_ACCOUNT, data }
}

export function workshopAccountCreationFailed (data) {
  return { type: WORKSHOP_ACCOUNT_CREATION_FAILED, data }
}

export function updateAllDmsData (data) {
  return { type: UPDATE_ALL_DMS, data }
}

export function updateWorkshopUsersData (data) {
  return { type: UPDATE_WORKSHOP_USERS_DATA, data }
}

export function updateSelectedAccount (data) {
  return { type: UPDATE_SELECTED_ACCOUNT, data }
}

export function updateWorkshopInProgress (data) {
  return { type: WORKSHOP_UPDATE_IN_PROGRESS, data }
}

export function updateWorkshopCompleted (data) {
  return { type: WORKSHOP_UPDATE_COMPLETED, data }
}

export function updateWorkshopAddressInProgress (data) {
  return { type: UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS, data }
}

export function updateWorkshopAddressCompleted (data) {
  return { type: UPDATE_WORKSHOP_ADDRESS_COMPLETED, data }
}

export function updatePartDealerInProgress (data) {
  return { type: UPDATE_PART_DEALER_IN_PROGRESS, data }
}

export function updatePartsDealerCompleted (data) {
  return { type: UPDATE_PART_DEALER_COMPLETED, data }
}

export function workshopUserAdded (data) {
  return { type: WORKSHOP_USER_ADDED, data }
}

export function updateWorkshopUser (data) {
  return { type: UPDATE_WORKSHOP_USER, data }
}

export function togglePartsDealerStatus (data) {
  return { type: TOGGLE_PARTS_DEALER_STATUS, data }
}

export function adminUserPreferences (data) {
  return { type: ADMIN_USER_PREFERENCES, data }
}

export function fetchAdminUserPreferences (data) {
  return { type: FETCH_ADMIN_PREFERENCES, data }
}

export function updateDmsIds (data) {
  return { type: UPDATE_DMS_IDS, data }
}

export function updateBrandList (data) {
  return { type: UPDATE_BRAND_LIST, data }
}

export function updateBrandGroups (data) {
  return { type: UPDATE_BRAND_GROUP_LIST, data }
}

export function updateWSGroups (data) {
  return { type: UPDATE_WS_GROUP_LIST, data }
}

export function addNewGroupToList (data) {
  return { type: ADD_NEW_BRAND_GROUP_TO_LIST, data }
}

export function deleteGroupFromList (data) {
  return { type: DELETE_BRAND_GROUP_FROM_LIST, data }
}

export function deleteWSFromList (data) {
  return { type: DELETE_WS_GROUP_FROM_LIST, data }
}

export function addNewPermissionToList (data) {
  return { type: ADD_NEW_PERMISSION_TO_LIST, data }
}

export function addNewWSGroupToList (data) {
  return { type: ADD_NEW_WS_GROUP_TO_LIST, data }
}

export function updateWSPermissions (data) {
  return { type: UPDATE_WS_PERMISSION_LIST, data }
}

export function updateCurrentWS (data) {
  return { type: UPDATE_CURRENT_WS, data }
}

export function updateCurrentBrandGroup (data) {
  return { type: UPDATE_CURRENT_BRAND_GROUP, data }
}

export function updateWSModes (data) {
  return { type: UPDATE_WS_MODES_LIST, data }
}

export function addWorkshopAccountWithGroup (data) {
  return { type: ADD_WORKSHOP_ACCOUNT_WITH_GROUP, data }
}

export function removeWorkshopAccountWithGroup (data) {
  return { type: REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP, data }
}
export function deletePermissionFromList (data) {
  return { type: DELETE_PERMISSION_FROM_LIST, data }
}

export function updateCurrentPermissionData (data) {
  return { type: UPDATE_CURRENT_PERMISSION_DATA, data }
}

export function tryFetchingWorkshopAccounts (pageNo, pageSize, sortBy, sortOrder, searchQuery, dmsIds) {
  return function (dispatch) {
    showLoader()
    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }
    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }
    if (dmsIds) {
      queryParams.dmsIds = dmsIds
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${WORKSHOP_ACCOUNTS_API}?${queryString}`
      : WORKSHOP_ACCOUNTS_API

    getData(endpointWithQuery)
      .then((response) => {
        hideLoader()
        dispatch(showWorkshopAccountData(response))
      })
  }
}

export function tryFetchingWorkshopAccountDetails (accountId) {
  return function (dispatch) {
    showLoader()
    getData(`${WORKSHOP_ACCOUNTS_API}/${accountId}`)
      .then((response) => {
        hideLoader()
        dispatch(updateWorkshopAccountDetails(response))
      })
  }
}

export function getWorkshopAccountById (pageNo, pageSize, sortBy, sortOrder, accountId) {
  return function (dispatch) {
    showLoader()
    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`).join('&')

    const endpointWithQuery = queryString
      ? `${WORKSHOP_ACCOUNTS_API}/${accountId}?${queryString}`
      : `${WORKSHOP_ACCOUNTS_API}/${accountId}`

    getData(`${endpointWithQuery}`)
      .then((response) => {
        hideLoader()
        dispatch(updateWorkshopAccounts(response))
      })
  }
}

export function getWorkshopById (workshopId) {
  return function (dispatch) {
    showLoader()
    getData(`${WORKSHOP_API}/${workshopId}`)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updateWorkshopData(response))
        }
      })
  }
}

export function tryCreatingWorkshopAccount (data) {
  return function (dispatch) {
    showLoader()
    postData(`${WORKSHOP_ACCOUNTS_API}`, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(workshopAccountCreated(response))
        } else {
          workshopAccountCreationFailed(response)
        }
      })
  }
}

export function tryCreatingWorkshopForAccount (data, workshopAccountId) {
  return function (dispatch) {
    showLoader()
    postData(`${WORKSHOP_ACCOUNTS_API}/${workshopAccountId}/workshops`, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(workshopCreatedForAccount(response))
        } else {
          workshopAccountCreationFailed(response)
        }
      })
  }
}

export function tryGettingAllDMSData () {
  return function (dispatch) {
    showLoader()
    getData(`${GET_ALL_DMS_API}`)
      .then(response => {
        hideLoader()
        dispatch(updateAllDmsData(response))
      })
  }
}

export function tryFetchingWorkshopUsersData (workshopId, page, pageSize, searchQuery) {
  return function (dispatch) {
    showLoader()
    const queryParams = {}
    if (page || pageSize) {
      queryParams.page = page
      queryParams.pageSize = pageSize
    }
    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${WORKSHOP_API}/${workshopId}/users?${queryString}`
      : `${WORKSHOP_API}/${workshopId}/users`

    getData(endpointWithQuery)
      .then(response => {
        hideLoader()
        dispatch(updateWorkshopUsersData(response))
      })
  }
}

export function tryUpdatingAccountDetails (accountId, data) {
  return function (dispatch) {
    showLoader()
    patchData(`${WORKSHOP_ACCOUNTS_API}/${accountId}`, data)
      .then(response => {
        hideLoader()
        dispatch(updateSelectedAccount(response, accountId))
      })
  }
}

export function tryUpdatingWorkshopData (workshopId, data) {
  return function (dispatch) {
    showLoader()
    dispatch(updateWorkshopInProgress(true))
    patchData(`${WORKSHOP_API}/${workshopId}`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(updateWorkshopCompleted(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryVerifyingWorkshopUsers (workshopId, data) {
  return function (dispatch) {
    showLoader()
    dispatch(updateWorkshopInProgress(true))
    patchData(`${WORKSHOP_API}/${workshopId}`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(updateWorkshopCompleted(response))
          dispatch(tryFetchingWorkshopUsersData(workshopId, 1, 20, null))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingWorkshopAddress (workshopId, data) {
  return function (dispatch) {
    showLoader()
    dispatch(updateWorkshopAddressInProgress(true))
    patchData(`${WORKSHOP_API}/${workshopId}`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(updateWorkshopAddressCompleted(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingPartDealers (workshopId, data) {
  return function (dispatch) {
    showLoader()
    dispatch(updatePartsDealerCompleted(true))
    patchData(`${WORKSHOP_API}/${workshopId}`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(updatePartsDealerCompleted(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryCreatingWorkshopUser (workshopId, data) {
  return function (dispatch) {
    showLoader()
    postData(`${WORKSHOP_API}/${workshopId}/users`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(workshopUserAdded(response?.data))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingWorkshopUser (email, data) {
  return function (dispatch) {
    showLoader()
    patchData(`${WORKSHOP_API}/users/${email}`, data)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(updateWorkshopUser(response?.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryTogglePartDealerStatus (id, status) {
  return function (dispatch) {
    showLoader()
    const data = { status }
    putData(`${PART_DEALERS_LISTING}/${id}`, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(togglePartsDealerStatus(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryShowHideColumnOfTable (data) {
  return function (dispatch) {
    showLoader()
    postData(`${ADMIN_USER_PREFERENCES_API}`, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(adminUserPreferences(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryFetchingAdminUserPreferences (userId, reportType) {
  return function (dispatch) {
    showLoader()
    getData(`${ADMIN_USER_PREFERENCES_API}?admin_user_id=${userId}&report_type=${reportType}`)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(fetchAdminUserPreferences(response))
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryFetchingBrands () {
  return function (dispatch) {
    showLoader()
    const url = `${BRAND_LIST_API}`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse?.code === SUCCESS_CODE) {
          dispatch(updateBrandList(jsonResponse?.data))
        }
      })
  }
}

export function tryFetchingBrandGroups (page, pageSize, searchQuery, sortBy, sortOrder) {
  const queryParams = {}
  if (page || pageSize) {
    queryParams.page = page
    queryParams.pageSize = pageSize
  }
  if (searchQuery) {
    queryParams.searchQuery = searchQuery
  }

  if (sortBy && sortOrder) {
    queryParams.sortBy = sortBy
    queryParams.sortOrder = sortOrder
  }

  const queryString = queryParams && Object.keys(queryParams).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

  const endpointWithQuery = queryString ? `${BRAND_GROUPS_LIST_API}?${queryString}` : `${BRAND_GROUPS_LIST_API}`
  return function (dispatch) {
    showLoader()
    const url = `${endpointWithQuery}`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse?.code === SUCCESS_CODE) {
          dispatch(updateBrandGroups(jsonResponse))
        }
      })
  }
}

export function tryFetchingWSGroups (page, pageSize, searchQuery, sortBy, sortOrder) {
  const queryParams = {}
  if (page || pageSize) {
    queryParams.page = page
    queryParams.pageSize = pageSize
  }
  if (searchQuery) {
    queryParams.searchQuery = searchQuery
  }

  if (sortBy && sortOrder) {
    queryParams.sortBy = sortBy
    queryParams.sortOrder = sortOrder
  }

  const queryString = queryParams && Object.keys(queryParams).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

  const endpointWithQuery = queryString ? `${WS_GROUPS_LIST_API}?${queryString}` : `${WS_GROUPS_LIST_API}`

  return function (dispatch) {
    showLoader()
    const url = `${endpointWithQuery}`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse?.code === SUCCESS_CODE) {
          dispatch(updateWSGroups(jsonResponse))
        }
      })
  }
}

export function tryCreatingBrandGroup (payload) {
  return function (dispatch) {
    showLoader()
    postData(`${BRAND_GROUPS_LIST_API}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(addNewGroupToList(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function trySynicingOrderTypes () {
  return function () {
    showLoader()
    getData(`${SYNC_ORDER_TYPE_API}`)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryFetchingWSPermissions (page, pageSize, searchQuery, sortBy, sortOrder, isDeleted) {
  const queryParams = {}
  if (page || pageSize) {
    queryParams.page = page
    queryParams.pageSize = pageSize
  }
  if (searchQuery) {
    queryParams.searchQuery = searchQuery
  }

  if (sortBy && sortOrder) {
    queryParams.sortBy = sortBy
    queryParams.sortOrder = sortOrder
  }

  if (isDeleted) {
    queryParams.isDeleted = true
  }

  const queryString = queryParams && Object.keys(queryParams).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

  const endpointWithQuery = queryString ? `${WS_PERMISSIONS_LIST_API}?${queryString}` : `${WS_PERMISSIONS_LIST_API}`
  return function (dispatch) {
    showLoader()
    const url = `${endpointWithQuery}`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse?.code === SUCCESS_CODE) {
          dispatch(updateWSPermissions(jsonResponse))
        }
      })
  }
}

export function tryCreatingWSPermissions (payload) {
  return function (dispatch) {
    showLoader()
    postData(`${WS_PERMISSIONS_CREATE_API}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(addNewPermissionToList(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingWSPermissions (payload, permissionId) {
  return function (dispatch) {
    showLoader()
    putData(`${WS_PERMISSIONS_CREATE_API}/${permissionId}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updateCurrentPermissionData(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryCreatingWSGroup (payload) {
  return function (dispatch) {
    showLoader()
    postData(`${WS_GROUPS_CREATE_API}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(addNewWSGroupToList(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryDeletingBrandGroup (groupId) {
  if (groupId) {
    return function (dispatch) {
      showLoader()
      deleteData(`${BRAND_GROUPS_LIST_API}/${groupId}`)
        .then(response => {
          hideLoader()
          if (response.code === SUCCESS_CODE) {
            dispatch(deleteGroupFromList({ id: groupId }))
            showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
          } else {
            showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
          }
        })
    }
  }
}

export function tryDeletingWSGroup (groupId) {
  if (groupId) {
    return function (dispatch) {
      showLoader()
      deleteData(`${WS_GROUPS_CREATE_API}/${groupId}`)
        .then(response => {
          hideLoader()
          if (response.code === SUCCESS_CODE) {
            dispatch(deleteWSFromList({ id: groupId }))
            showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
          } else {
            showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
          }
        })
    }
  }
}

export function tryUpdatingBrandGroup (payload, id) {
  return function (dispatch) {
    showLoader()
    patchData(`${BRAND_GROUPS_LIST_API}/${id}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updateCurrentBrandGroup(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingWSGroup (payload, id) {
  return function (dispatch) {
    showLoader()
    patchData(`${WS_GROUPS_CREATE_API}/${id}`, payload)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(updateCurrentWS(response.data))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryFetchingWSModes (wsId) {
  return function (dispatch) {
    showLoader()
    const url = `${WORKSHOP_API}/${wsId}/modes?sortBy=createdAt&sortOrder=DESC`
    getData(url)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse?.code === SUCCESS_CODE) {
          dispatch(updateWSModes(jsonResponse))
        }
      })
  }
}

export function tryUpdatingWorkshopAccountWithWsGroup (id, data, mode) {
  return function (dispatch) {
    showLoader()
    putData(`${WORKSHOP_GROUP_ASSOC_API}/${id}`, data)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          if (mode === 'addGroup') {
            dispatch(addWorkshopAccountWithGroup(response.data))
          } else if (mode === 'removeGroup') {
            dispatch(removeWorkshopAccountWithGroup(response.data))
          }
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
export function deleteWSPermission (permissionId) {
  if (permissionId) {
    return function (dispatch) {
      showLoader()
      deleteData(`${WS_PERMISSIONS_CREATE_API}/${permissionId}`)
        .then(response => {
          hideLoader()
          if (response?.code === SUCCESS_CODE) {
            dispatch(deletePermissionFromList({ id: permissionId }))
            showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
          } else {
            showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
          }
        })
    }
  }
}
