import React, { useEffect, useState } from 'react'
import '../src/assets/stylesheets/webpixels.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Navigation from './components/navigation/Navigation'

import '../src/assets/stylesheets/shared.scss'
import '../src/assets/stylesheets/panelMargins.scss'

import { ROUTES } from './router'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import * as workshopActions from './actions/workshop'
import * as accountActions from './actions/account'
import * as auftragActions from './actions/auftrag'
import * as dashboardActions from './actions/dashboard'
import * as contactActions from './actions/contactInfo'
import * as uploadCatalogActions from './actions/uploadCatalog'
import * as brandsAssociationActions from './actions/brandsAssociation'
import * as rolesActions from './actions/roles'

import { connect } from 'react-redux'
import Loader from './components/common/Loader'
import Toaster from './components/common/Toaster'
import { DELAY, ENVIRONMENTS, TOAST_ERROR, TOAST_SUCCESS } from './constants/siteConstant'

function App (props) {
  const [toastOpen, setToastOpen] = useState({
    isOpen: false,
    toastMessage: '',
    type: TOAST_SUCCESS
  })

  useEffect(() => {
    const appendNewRelicScript = () => {
      // eslint-disable-next-line no-undef
      if (process.env.REACT_APP_NODE_ENV === ENVIRONMENTS.PRODUCTION) {
        const script = document.createElement('script')
        script.src = '/scripts/newRelic.js'
        script.async = true
        script.onload = () => console.log('New Relic script loaded.')
        script.onerror = () => console.error('Failed to load New Relic script.')
        document.body.appendChild(script)
        return () => {
          if (script.parentNode) {
            document.body.removeChild(script)
          }
        }
      }
    }

    const cleanup = appendNewRelicScript()
    return cleanup
  }, [])

  useEffect(() => {
    props.actions.accountActions.tryFetchingAccountData()
  }, [props.actions.accountActions])

  useEffect(() => {
    window.addEventListener('showToaster', showToaster)
    window.addEventListener('hideToaster', hideToaster)
    return () => {
      window.removeEventListener('showToaster', showToaster)
      window.removeEventListener('hideToaster', hideToaster)
    }
  }, [])

  const showToaster = (event) => {
    if (event?.detail?.type === TOAST_SUCCESS) {
      setToastOpen({ isOpen: true, toastMessage: event.detail?.message, type: TOAST_SUCCESS })
    } else {
      setToastOpen({ isOpen: true, toastMessage: event.detail?.message, type: TOAST_ERROR })
    }
    setTimeout(() => {
      hideToaster()
    }, DELAY)
  }

  const hideToaster = () => {
    setToastOpen({ isOpen: false, toastMessage: '', type: TOAST_SUCCESS })
  }

  return (
    <div className='application-wrapper d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary'>
      <Router>
        <Loader />
        {toastOpen.isOpen &&
          <div>
            <Toaster
              toastOpen={toastOpen}
              onClose={hideToaster}
            />
          </div>}
        <Routes>
          <Route element={<Navigation accountData={props?.accountStateData?.accountData} />}>
            {ROUTES(props)?.map((route) => (
              <Route
                path={route.path}
                key={route.key}
                exact={route.exact}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    workshopStateData: state.workshop,
    accountStateData: state.account,
    auftragStateData: state.auftrag,
    dashboardStateData: state.dashboardReducer,
    contactInfoStateData: state.contactInfo,
    uploadCatalogData: state.uploadCatalog,
    brandsAssociationStateData: state.brandsAssociation,
    rolesStateData: state.roles.adminRolesData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      workshopActions: bindActionCreators(workshopActions, dispatch),
      accountActions: bindActionCreators(accountActions, dispatch),
      auftragActions: bindActionCreators(auftragActions, dispatch),
      dashboardActions: bindActionCreators(dashboardActions, dispatch),
      contactActions: bindActionCreators(contactActions, dispatch),
      uploadCatalogActions: bindActionCreators(uploadCatalogActions, dispatch),
      brandsAssociationActions: bindActionCreators(brandsAssociationActions, dispatch),
      rolesActions: bindActionCreators(rolesActions, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
