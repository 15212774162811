export const WORKSHOP_INITIAL_STATE = {
  workshopAccountData: [],
  workshopAccountDetailsData: '',
  workshopDetailsData: {},
  workshopAccountCreationResponse: '',
  allDMSData: [],
  workshopUsersData: [],
  workshopModesData: [],
  workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: false },
  isWorkshopAccountCreated: false,
  adminUserPreferenceData: '',
  dmsIds: '',
  brandList: [],
  brandGroupData: {},
  wsGroupData: {},
  wsPermissionsData: {}
}

export const ACCOUNT_INITIAL_STATE = {
  accountData: []
}

export const AUFTRAG_INITIAL_STATE = {
  auftragData: [],
  auftragOeParts: [],
  eventsData: [],
  eventsRequestResponseData: '',
  changeLogData: '',
  partDealer: null,
  selectedStatus: [],
  startDate: null,
  endDate: null,
  orderType: null
}

export const DASHBOARD_INIT_STATE = {
  isFetching: false,
  showDashboardData: false,
  isError: { isError: false, errorMsg: '', type: '' },
  allAuftragData: {},
  currentAuftragData: {},
  isAuftragLogs: false,
  auftragLogs: [],
  apiResponse: { code: '', message: '', type: '' },
  isLogout: false,
  validationData: {},
  stockData: {},
  orderData: {},
  uploadingInProgress: false,
  pdfUrl: '',
  ocrResonseData: {},
  isUpdatingData: false,
  profileData: {},
  analyticsWorkshopData: {},
  analyticsDealerData: {},
  analyticsBrandData: [],
  workshopAccountsList: []
}

export const CONTACT_INITIAL_STATE = {
  contactInfoData: []
}

export const UPLOAD_CATALOG_INIT_STATE = {
  catalogData: {}
}

export const BRANDS_ASSOCIATION_STATE = {
  brandsAssociationData: []
}

export const ROLES_INITIAL_STATE = {
  adminRolesData: []
}
