import { SHOW_CONTACT_INFO_DATA } from '../constants/actionTypes'
import { CONTACT_INITIAL_STATE } from '../constants/initialState'

export default function contactInfo (state = CONTACT_INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_CONTACT_INFO_DATA:
      return {
        ...state,
        contactInfoData: action?.data
      }
    default:
      return state
  }
}
