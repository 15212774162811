import { SHOW_ERROR, UPLOAD_CATALOG, FETCH_CATALOG_DATA } from '../constants/actionTypes'
import { FILE_UPLOAD_API, SHOW_CATALOG_DATA_API } from '../constants/endpoints'
import { SUCCESS_CODE, TOAST_ERROR, TOAST_SUCCESS } from '../constants/siteConstant'
import { getData, postData } from '../helper/apiCall'
import { hideLoader, showLoader, showToaster } from '../helper/Utils'

export function uploadCatalog (data) {
  return { type: UPLOAD_CATALOG, data }
}
export function showError (error) {
  return { type: SHOW_ERROR, error }
}

export function fetchCatalogData (data) {
  return { type: FETCH_CATALOG_DATA, data }
}

export function tryFetchingCatalogData () {
  return function (dispatch) {
    showLoader()
    getData(`${SHOW_CATALOG_DATA_API}`)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(fetchCatalogData(response))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
export function tryUploadingCatalogueData (dealerName, fileKey) {
  return function (dispatch) {
    showLoader()
    postData(`${FILE_UPLOAD_API}`, ({ dealer_name: dealerName, file_key: fileKey }))
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(uploadCatalog(response))
          showToaster({ message: (response?.message || response?.data?.message), type: TOAST_SUCCESS })
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
