import { SHOW_ALL_ROLES, UPDATE_ROLE } from '../constants/actionTypes'
import { SHOW_ALL_ROLE_API } from '../constants/endpoints'
import { SUCCESS_CODE, TOAST_ERROR, TOAST_SUCCESS } from '../constants/siteConstant'
import { getData, patchData } from '../helper/apiCall'
import { hideLoader, showLoader, showToaster } from '../helper/Utils'

export function showAllRoles (data) {
  return { type: SHOW_ALL_ROLES, data }
}

export function updateAdminRole (data) {
  return { type: UPDATE_ROLE, data }
}

export function tryFechingAdminRoles (pageNo, pageSize, sortBy, sortOrder, searchQuery) {
  return function (dispatch) {
    showLoader()
    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }

    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${SHOW_ALL_ROLE_API}?${queryString}`
      : SHOW_ALL_ROLE_API

    getData(endpointWithQuery)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(showAllRoles(response))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingAdminRole (id, data) {
  return function (dispatch) {
    showLoader()
    patchData(`${SHOW_ALL_ROLE_API}/${id}`, data)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(updateAdminRole(response))
          showToaster({ message: 'Role has been updated successfully', type: TOAST_SUCCESS })
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
