import { BE_ROOT_URL, FETCH_AUFTRAG_DATA, LOGOUT_API, LOGIN_URL, GET_PROFILE_DATA, WORKSHOP_ACCOUNTS_API, ADMIN_ANALYTICS_API_BASE_URL } from '../constants/endpoints'
import {
  SHOW_ALL_AUFTRAG_DATA,
  SHOW_AUFTRAG_LOGS,
  SINGLE_AUFTRAG_DATA,
  FETCHING_AUFTRAG_DATA,
  SHOW_ERROR, SORT_BY_DATE,
  LOGOUT_COMPLETE,
  CREATE_ORDER, UPDATE_VALIDATION_DATA, UPDATE_STOCK_DATA, UPDATE_ORDER_DATA,
  UPLOADING_IN_PROGRESS,
  UPLOAD_PDF_FILE,
  START_OCR_ANALYSIS,
  START_UPDATING_DATA,
  UPDATED_DATA,
  SHOW_PROFILE_DATA,
  SET_ANALYTICS_WORKSHOP_DATA,
  SET_ANALYTICS_DEALERS_DATA,
  SET_ANALYTICS_BRAND_DATA,
  SET_WORKSHOPS_ACCOUNTS_LIST
} from '../constants/actionTypes'
import { SUCCESS_CODE } from '../constants/siteConstant'
import { hideLoader, showLoader } from '../helper/Utils'
import { getData, postData, putData } from '../helper/apiCall'

export function fetchingAuftragData (data) {
  return { type: FETCHING_AUFTRAG_DATA, data }
}

export function showAllAuftragData (data) {
  return { type: SHOW_ALL_AUFTRAG_DATA, data }
}

export function showAuftragById (data) {
  return { type: SINGLE_AUFTRAG_DATA, data }
}

export function showError (error) {
  return { type: SHOW_ERROR, error }
}

export function sortingByDate (data) {
  return { type: SORT_BY_DATE, data }
}

function showAuftragLogs (data) {
  return { type: SHOW_AUFTRAG_LOGS, data }
}

function createOrder (data) {
  return { type: CREATE_ORDER, data }
}

function logoutCompleted (data) {
  return { type: LOGOUT_COMPLETE, data }
}

export function updateValidationData (data) {
  return { type: UPDATE_VALIDATION_DATA, data }
}

export function updateStockData (data) {
  return { type: UPDATE_STOCK_DATA, data }
}

export function updateOrderData (data) {
  return { type: UPDATE_ORDER_DATA, data }
}

export function uploadingInProgress (data) {
  return { type: UPLOADING_IN_PROGRESS, data }
}

export function uploadPdfFile (data) {
  return { type: UPLOAD_PDF_FILE, data }
}

export function startOcrAnalysis (data) {
  return { type: START_OCR_ANALYSIS, data }
}

export function startUpdatingData (data) {
  return { type: START_UPDATING_DATA, data }
}

export function updatedAuftragData (data) {
  return { type: UPDATED_DATA, data }
}

export function showProfileData (data) {
  return { type: SHOW_PROFILE_DATA, data }
}

export function setAnalyticsWorkshopData (data) {
  return { type: SET_ANALYTICS_WORKSHOP_DATA, data }
}

export function setAnalyticsDealersData (data) {
  return { type: SET_ANALYTICS_DEALERS_DATA, data }
}

export function setAnalyticsBrandData (data) {
  return { type: SET_ANALYTICS_BRAND_DATA, data }
}

export function setWorkshopsAccountsListData (data) {
  return { type: SET_WORKSHOPS_ACCOUNTS_LIST, data }
}

export function tryFetchingAuftragData (sortBy, sortOrder, pageNo, pageSize, searchByBrand, auftragId, status) {
  return function (dispatch) {
    dispatch(fetchingAuftragData(true))
    showLoader()
    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }

    if (searchByBrand) {
      queryParams.searchQuery = searchByBrand
    }
    if (auftragId) {
      queryParams.auftragId = auftragId
    }
    if (status) {
      queryParams.status = status
    }
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`).join('&')

    const endpointWithQuery = queryString
      ? `${FETCH_AUFTRAG_DATA}?${queryString}`
      : FETCH_AUFTRAG_DATA

    getData(endpointWithQuery)
      .then((response) => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(showAllAuftragData(response))
        } else {
          dispatch(showError(response?.message || 'An error occurred'))
        }
      })
  }
}

export function tryFetchingAuftragById (id) {
  return function (dispatch) {
    showLoader()
    getData(`${FETCH_AUFTRAG_DATA}/${id}`)
      .then(response => {
        hideLoader()
        if (response?.code === SUCCESS_CODE) {
          dispatch(showAuftragById(response))
        } else {
          dispatch(showError(response?.message || 'An error occurred'))
        }
      })
  }
}

export function tryFetchingAuftragLogs (auftragId) {
  return function (dispatch) {
    showLoader()
    getData(`${FETCH_AUFTRAG_DATA}/${auftragId}/logs`)
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(showAuftragLogs(response))
        } else {
          dispatch(showError(response.message || 'An error occurred'))
        }
      })
  }
}

export function tryCreatingOrder (auftragId, auftragItems) {
  return function (dispatch) {
    showLoader()
    postData(`${FETCH_AUFTRAG_DATA}/${auftragId}/place-order`, { auftragItems })
      .then(response => {
        hideLoader()
        if (response.code === SUCCESS_CODE) {
          dispatch(createOrder(response))
        } else {
          dispatch(showError(response.message || 'An error occurred'))
        }
      })
  }
}

export function tryStartingOcrAnalysis (id, selectedFileFormat) {
  return function (dispatch) {
    const START_OCR_ANALYSIS_URL = selectedFileFormat ? `${BE_ROOT_URL}/v1/auftrag/uploads/${id}/start-ocr-analysis?ocr_pdf_format=${selectedFileFormat}` : `${BE_ROOT_URL}/v1/auftrag/uploads/${id}/start-ocr-analysis`
    showLoader()
    postData(START_OCR_ANALYSIS_URL)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(startOcrAnalysis(jsonResponse))
        } else {
          dispatch(showError(jsonResponse.message))
        }
      })
  }
}

export function tryingStartUpdateData (aufId, updatedData) {
  return function (dispatch) {
    dispatch(startUpdatingData(true))
    const UPDATE_AUFTRAG_DATA = `${FETCH_AUFTRAG_DATA}/${aufId}`
    showLoader()
    putData(UPDATE_AUFTRAG_DATA, updatedData)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(updatedAuftragData(jsonResponse))
        } else {
          dispatch(showError(jsonResponse.error))
        }
      })
  }
}

export function tryFetchingProfileData () {
  return function (dispatch) {
    showLoader()
    getData(GET_PROFILE_DATA)
      .then(jsonResponse => {
        hideLoader()
        dispatch(showProfileData(jsonResponse))
      })
  }
}

export function tryLogout () {
  return function (dispatch) {
    postData(LOGOUT_API)
      .then(jsonResponse => {
        if (jsonResponse.code === SUCCESS_CODE) {
          window.localStorage.removeItem('userResponse')
          window.location.href = LOGIN_URL
          dispatch(logoutCompleted(true))
        } else {
          dispatch(showError(jsonResponse.message))
        }
      })
  }
}

export function fetchWorkshopAnalyticsData (data) {
  return function (dispatch) {
    showLoader()
    postData(ADMIN_ANALYTICS_API_BASE_URL + '/workshop-orders-data', data, false)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(setAnalyticsWorkshopData(jsonResponse))
        } else {
          dispatch(showError(jsonResponse.message))
        }
      })
  }
}

export function fetchDealerAnalyticsData (data) {
  return function (dispatch) {
    showLoader()
    postData(ADMIN_ANALYTICS_API_BASE_URL + '/parts-orders-dealer', data, false)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(setAnalyticsDealersData(jsonResponse))
        } else {
          dispatch(showError(jsonResponse.message))
        }
      })
  }
}

export function fetchBrandAnalyticsData (data) {
  return function (dispatch) {
    showLoader()
    postData(ADMIN_ANALYTICS_API_BASE_URL + '/parts-orders-brand', data, false)
      .then(jsonResponse => {
        hideLoader()
        if (jsonResponse.code === SUCCESS_CODE) {
          dispatch(setAnalyticsBrandData(jsonResponse))
        } else {
          dispatch(showError(jsonResponse.message))
        }
      })
  }
}

export function fetchAllWorkshopAccounts () {
  return function (dispatch) {
    showLoader()
    getData(`${WORKSHOP_ACCOUNTS_API}?pageSize=${Number.MAX_SAFE_INTEGER}&sortBy=name&sortOrder=ASC`)
      .then((response) => {
        hideLoader()
        dispatch(setWorkshopsAccountsListData(response))
      })
  }
}

export function fetchWorkshopsByAccountId (id) {
  return async function () {
    showLoader()
    const response = await getData(`${WORKSHOP_ACCOUNTS_API}/${id}?pageSize=${Number.MAX_SAFE_INTEGER}&sortBy=name&sortOrder=ASC`)
    hideLoader()
    return response
  }
}
