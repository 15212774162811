import React from 'react'
import { ACCOUNTS_PATH, AUFTRAG_PATH, DASHBOARD_PATH, LEADS_PATH, ORDERS_PATH, REPORT_PATH, WORKSHOP_ACCOUNT_PATH, CONTACT_INFO_PATH, UPLOAD_CATALOG_PATH, BRAND_ASSOCIATION_PATH, BRAND_GROUPS_PATH, WORKSHOP_GROUPS_PATH, WORKSHOP_PERMISSIONS_PATH, ROLE_PATH, DECLARATION_OF_USE_PATH } from '../constants/endpoints'

export const sidebarMenuItems = ['dashboard', 'workshop', 'auftrag', 'leads', 'contactInfo', 'brandsAssociation', 'groupsAndPermissions', 'uploadCatalog', 'roles', 'declaration_of_use']

export const menuItems = {
  dashboard: {
    icon: <i className='bi bi-house' />,
    name: 'Dashboard',
    url: DASHBOARD_PATH
  },
  workshop: {
    icon: <i className='bi bi-gear-wide-connected' />,
    name: 'Workshop Account',
    url: WORKSHOP_ACCOUNT_PATH
  },
  auftrag: {
    icon: <i className='bi bi-bar-chart' />,
    name: 'Auftrag',
    url: AUFTRAG_PATH
  },
  leads: {
    icon: <i className='bi bi-person-standing' />,
    name: 'Lead',
    url: LEADS_PATH
  },
  contactInfo: {
    icon: <i className='bi bi-person-lines-fill' />,
    name: 'Contact Info',
    url: CONTACT_INFO_PATH
  },
  uploadCatalog: {
    icon: <i className='bi bi-cloud-arrow-up-fill' />,
    name: 'Upload Catalog',
    url: UPLOAD_CATALOG_PATH
  },
  roles: {
    icon: <i className='bi bi-person-badge' />,
    name: 'All Roles',
    url: ROLE_PATH
  },
  brandsAssociation: {
    icon: <i className='bi bi-diagram-3-fill' />,
    name: 'Brands Association',
    url: BRAND_ASSOCIATION_PATH
  },
  declaration_of_use: {
    icon: <i className='bi bi-file-earmark-text-fill' />,
    name: 'Verwendungserklärung',
    url: DECLARATION_OF_USE_PATH
  },
  groupsAndPermissions: {
    icon: <i className='bi bi-shield-lock' />,
    name: 'Permissions',
    subItems: [
      {
        name: 'Brand Groups',
        url: BRAND_GROUPS_PATH
      },
      {
        name: 'Workshop Groups',
        url: WORKSHOP_GROUPS_PATH
      },
      {
        name: 'Workshop Permissions',
        url: WORKSHOP_PERMISSIONS_PATH
      }
    ]
  },
  accounts: {
    icon: <i className='bi bi-person-circle' />,
    name: 'Accounts',
    url: ACCOUNTS_PATH
  },
  report: {
    icon: <i className='bi bi-people' />,
    name: 'Report',
    url: REPORT_PATH
  },
  orders: {
    icon: <i className='bi bi-people' />,
    name: 'Orders',
    url: ORDERS_PATH
  }
}
