import { WORKSHOP_INITIAL_STATE } from '../constants/initialState'
import {
  SHOW_WORKSHOP_ACCOUNT_DATA,
  UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS,
  UPDATE_WORKSHOP_ADDRESS_COMPLETED,
  UPDATE_PART_DEALER_IN_PROGRESS,
  UPDATE_PART_DEALER_COMPLETED,
  UPDATE_WORKSHOP_ACCOUNT,
  WORKSHOP_UPDATE_COMPLETED,
  UPDATE_WORKSHOP_BY_ID_DATA,
  UPDATE_WORKSHOP_ACCOUNT_DETAILS,
  WORKSHOP_ACCOUNT_CREATED,
  WORKSHOP_ACCOUNT_CREATION_FAILED,
  UPDATE_ALL_DMS,
  WORKSHOP_UPDATE_IN_PROGRESS,
  UPDATE_WORKSHOP_USERS_DATA,
  UPDATE_SELECTED_ACCOUNT,
  WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS,
  WORKSHOP_USER_ADDED,
  UPDATE_WORKSHOP_USER,
  TOGGLE_PARTS_DEALER_STATUS,
  WORKSHOP_CREATED_FOR_ACCOUNT,
  ADMIN_USER_PREFERENCES, UPDATE_CURRENT_PERMISSION_DATA,
  FETCH_ADMIN_PREFERENCES, DELETE_WS_GROUP_FROM_LIST, UPDATE_CURRENT_WS, UPDATE_CURRENT_BRAND_GROUP, UPDATE_WS_MODES_LIST, DELETE_PERMISSION_FROM_LIST,
  UPDATE_DMS_IDS, UPDATE_BRAND_LIST, UPDATE_WS_GROUP_LIST, UPDATE_WS_PERMISSION_LIST, DELETE_BRAND_GROUP_FROM_LIST,
  UPDATE_BRAND_GROUP_LIST, ADD_NEW_BRAND_GROUP_TO_LIST, ADD_NEW_PERMISSION_TO_LIST, ADD_NEW_WS_GROUP_TO_LIST,
  ADD_WORKSHOP_ACCOUNT_WITH_GROUP,
  REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP
} from '../constants/actionTypes'

export default function workshop (state = WORKSHOP_INITIAL_STATE, action) {
  let indexToSearch; let updatedData; let arrayToParse = []; let updatedPartsDealer

  switch (action.type) {
    case SHOW_WORKSHOP_ACCOUNT_DATA:
      return {
        ...state,
        isWorkshopAccountCreated: false,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: false },
        workshopAccountData: action?.data
      }
    case UPDATE_ALL_DMS:
      return {
        ...state,
        allDMSData: action?.data
      }
    case UPDATE_WORKSHOP_ACCOUNT_DETAILS:
      return {
        ...state,
        workshopAccountDetailsData: action?.data
      }
    case WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS:
      return {
        ...state,
        isWorkshopAccountCreated: false
      }
    case WORKSHOP_ACCOUNT_CREATED:
      return {
        ...state,
        isWorkshopAccountCreated: true,
        workshopAccountCreationResponse: action?.data,
        workshopAccountData: {
          ...state.workshopAccountData,
          data: [
            action?.data?.data,
            ...state.workshopAccountData.data
          ]
        }
      }
    case WORKSHOP_CREATED_FOR_ACCOUNT:
      return {
        ...state,
        isWorkshopAccountCreated: true
      }
    case WORKSHOP_ACCOUNT_CREATION_FAILED:
      return {
        ...state,
        isWorkshopAccountCreated: false
      }
    case UPDATE_WORKSHOP_USERS_DATA:
      return {
        ...state,
        workshopUsersData: action?.data
      }

    case UPDATE_WS_MODES_LIST:
      return {
        ...state,
        workshopModesData: action?.data
      }
    case UPDATE_WORKSHOP_ACCOUNT:
      return {
        ...state,
        workshopAccountData: action?.data
      }
    case WORKSHOP_UPDATE_IN_PROGRESS:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: true, isAddressUpdate: false, isPartDealerUpdate: false }
      }
    case WORKSHOP_UPDATE_COMPLETED:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: false },
        workshopAccountCreationResponse: action?.data,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          ...action?.data?.data
        }
      }

    case UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: true, isPartDealerUpdate: false }
      }

    case UPDATE_WORKSHOP_ADDRESS_COMPLETED:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: false },
        workshopAccountCreationResponse: action?.data,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          ...action?.data?.data
        }
      }

    case WORKSHOP_USER_ADDED:
      arrayToParse = [...state.workshopUsersData.data]
      arrayToParse.unshift(action.data)
      return {
        ...state,
        workshopUsersData: { ...state.workshopUsersData, data: arrayToParse }
      }

    case UPDATE_WORKSHOP_USER:
      arrayToParse = [...state.workshopUsersData.data]
      indexToSearch = arrayToParse.findIndex(user => user.id === action?.data?.id)
      if (indexToSearch !== -1) {
        arrayToParse[indexToSearch] = action?.data
      }
      return {
        ...state,
        workshopUsersData: { ...state.workshopUsersData, data: arrayToParse }
      }

    case UPDATE_PART_DEALER_IN_PROGRESS:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: true }
      }

    case UPDATE_PART_DEALER_COMPLETED:
      return {
        ...state,
        workshopUpdateInProgress: { isWorkshopUpdate: false, isAddressUpdate: false, isPartDealerUpdate: false },
        workshopAccountCreationResponse: action?.data,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          ...action?.data?.data
        }
      }

    case UPDATE_SELECTED_ACCOUNT:
      indexToSearch = state?.workshopAccountData?.data?.findIndex(element => (element.id === action?.data?.data?.id))
      updatedData = [...(state?.workshopAccountData?.data ?? [])]
      if (indexToSearch >= 0) {
        updatedData[indexToSearch] = action.data.data
      }

      return {
        ...state,
        workshopAccountData: {
          ...state.workshopAccountData,
          data: updatedData
        }
      }

    case UPDATE_WORKSHOP_BY_ID_DATA:
      return {
        ...state,
        workshopDetailsData: action?.data?.data
      }
    case TOGGLE_PARTS_DEALER_STATUS:
      updatedPartsDealer = state.workshopDetailsData.part_dealers.map(item => (
        item?.id === action?.data?.data?.id ? { ...item, status: action?.data?.data.status } : item
      ))
      return {
        ...state,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          part_dealers: updatedPartsDealer
        }
      }
    case ADMIN_USER_PREFERENCES:
      return {
        ...state,
        adminUserPreferenceData: action?.data
      }
    case FETCH_ADMIN_PREFERENCES:
      return {
        ...state,
        adminUserPreferenceData: action?.data
      }
    case UPDATE_DMS_IDS:
      return {
        ...state,
        dmsIds: action?.data
      }
    case UPDATE_BRAND_LIST:
      return {
        ...state,
        brandList: action?.data
      }

    case UPDATE_BRAND_GROUP_LIST:
      return {
        ...state,
        brandGroupData: action?.data
      }

    case ADD_NEW_BRAND_GROUP_TO_LIST:
      arrayToParse = [...state.brandGroupData.data]
      arrayToParse.unshift(action.data)
      return {
        ...state,
        brandGroupData: { ...state.brandGroupData, data: arrayToParse }
      }

    case DELETE_BRAND_GROUP_FROM_LIST:
      arrayToParse = [...state.brandGroupData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse.splice(indexToSearch, 1)
      }
      return {
        ...state,
        brandGroupData: { ...state.brandGroupData, data: arrayToParse }
      }

    case DELETE_WS_GROUP_FROM_LIST:
      arrayToParse = [...state.wsGroupData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse.splice(indexToSearch, 1)
      }
      return {
        ...state,
        wsGroupData: { ...state.wsGroupData, data: arrayToParse }
      }

    case DELETE_PERMISSION_FROM_LIST:
      arrayToParse = [...state.wsPermissionsData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse[indexToSearch] = { ...arrayToParse[indexToSearch], deletedAt: new Date() }
      }
      return {
        ...state,
        wsPermissionsData: { ...state.wsPermissionsData, data: arrayToParse }
      }

    case ADD_NEW_PERMISSION_TO_LIST:
      arrayToParse = [...state.wsPermissionsData.data]
      arrayToParse.unshift(action.data)
      return {
        ...state,
        wsPermissionsData: { ...state.wsPermissionsData, data: arrayToParse }
      }

    case ADD_NEW_WS_GROUP_TO_LIST:
      arrayToParse = [...state.wsGroupData.data]
      arrayToParse.unshift(action.data)
      return {
        ...state,
        wsGroupData: { ...state.wsGroupData, data: arrayToParse }
      }

    case UPDATE_WS_GROUP_LIST:
      return {
        ...state,
        wsGroupData: action?.data
      }

    case UPDATE_WS_PERMISSION_LIST:
      return {
        ...state,
        wsPermissionsData: action?.data
      }

    case UPDATE_CURRENT_WS:
      arrayToParse = [...state.wsGroupData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse[indexToSearch] = action.data
      }
      return {
        ...state,
        wsGroupData: { ...state.wsGroupData, data: arrayToParse }
      }

    case UPDATE_CURRENT_BRAND_GROUP:
      arrayToParse = [...state.brandGroupData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse[indexToSearch] = action.data
      }
      return {
        ...state,
        brandGroupData: { ...state.brandGroupData, data: arrayToParse }
      }
    case ADD_WORKSHOP_ACCOUNT_WITH_GROUP:
      return {
        ...state,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          workshop_groups: [action?.data, ...state.workshopDetailsData.workshop_groups]
        }
      }
    case REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP: {
      const updatedWsGroup = state.workshopDetailsData.workshop_groups?.filter(item => item.id !== action?.data?.id)
      return {
        ...state,
        workshopDetailsData: {
          ...state.workshopDetailsData,
          workshop_groups: updatedWsGroup
        }
      }
    }

    case UPDATE_CURRENT_PERMISSION_DATA:
      arrayToParse = [...state.wsPermissionsData.data]
      indexToSearch = arrayToParse?.findIndex(element => element.id === action.data.id)
      if (indexToSearch > -1) {
        arrayToParse[indexToSearch] = action.data
      }
      return {
        ...state,
        wsPermissionsData: { ...state.wsPermissionsData, data: arrayToParse }
      }

    default:
      return state
  }
}
