import axios from 'axios'
import { showToaster } from './Utils'
import { TOAST_ERROR, TOAST_SUCCESS } from '../constants/siteConstant'
import { LOGIN_URL, RESET_PASSWORD_URL } from '../constants/endpoints'
axios.defaults.withCredentials = true

const headers = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const getData = (endpoint) => {
  return axios
    .get(endpoint)
    .then((res) => {
      return res?.data
    }).catch(error => {
      showToaster({ message: error?.response?.data?.message || error?.message, type: TOAST_ERROR })
      handleError(error.response)
      return error?.response?.data
    })
}

export const postData = (endpoint, data, showToast = true) => {
  return axios
    .post(endpoint, data, headers)
    .then((res) => {
      if (showToast) {
        showToaster({ message: res?.data?.message, type: TOAST_SUCCESS })
      }
      return res?.data
    }).catch(error => {
      showToaster({ message: error?.response?.data?.message || error?.response?.error || 'Error', type: TOAST_ERROR })
      return error.response.data
    })
}

export const putData = (endpoint, data) => {
  return axios
    .put(endpoint, data, headers)
    .then((res) => {
      showToaster({ message: res?.data?.message, type: TOAST_SUCCESS })
      return res?.data
    }).catch(error => {
      showToaster({ message: error?.response?.data?.message || error?.message, type: TOAST_ERROR })
      return error.response.data
    })
}

export const patchData = (endpoint, data) => {
  return axios
    .patch(endpoint, data, headers)
    .then((res) => {
      showToaster({ message: res?.data?.message, type: TOAST_SUCCESS })
      return res?.data
    }).catch(error => {
      showToaster({ message: error?.response?.data?.message || error?.message, type: TOAST_ERROR })
      return error?.response?.data
    })
}

export const deleteData = (endpoint) => {
  return axios
    .delete(endpoint)
    .then((res) => {
      showToaster({ message: res?.data?.message, type: TOAST_SUCCESS })
      return res?.data
    })
    .catch(function (error) {
      showToaster({ message: error?.response?.data?.message || error?.message, type: TOAST_ERROR })
    })
}

function handleError (error) {
  switch (error?.status) {
    case 401:
      setTimeout(() => {
        redirectToLogin()
      }, 1000)
      break
    case 403:
      setTimeout(() => {
        redirectToResetUrl()
      }, 1000)
      break
    // case 404:
    //   setTimeout(() => {
    //     redirectToLogin()
    //   }, 1000)
  }
}

function redirectToResetUrl () {
  const reference = window?.location?.href
  window.location = `${RESET_PASSWORD_URL}?redirectUrl=` + reference
}

function redirectToLogin () {
  const reference = window?.location?.href
  window.location = `${LOGIN_URL}?redirectUrl=` + reference
}
