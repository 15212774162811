import { combineReducers } from 'redux'
import workshop from './workshop'
import account from './account'
import auftrag from './auftrag'
import dashboardReducer from './dashboardReducer'
import contactInfo from './contactInfo'
import uploadCatalog from './uploadCatalog'
import brandsAssociation from './brandsAssociation'
import roles from './roles'

const mainReducer = combineReducers({
  workshop,
  account,
  auftrag,
  dashboardReducer,
  contactInfo,
  uploadCatalog,
  brandsAssociation,
  roles
})

export default mainReducer
