import { SHOW_ALL_ROLES, UPDATE_ROLE } from '../constants/actionTypes'
import { ROLES_INITIAL_STATE } from '../constants/initialState'

export default function roles (state = ROLES_INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_ALL_ROLES:
      return {
        ...state,
        adminRolesData: action?.data
      }
    case UPDATE_ROLE: {
      const updatedRole = action?.data?.data
      const updatedAdminRolesData = state?.adminRolesData?.data?.map((role) =>
        role.id === updatedRole.id ? { ...role, ...updatedRole } : role
      )
      return {
        ...state,
        adminRolesData: {
          data: updatedAdminRolesData
        }
      }
    }
    default:
      return state
  }
}
