import { SHOW_ACCOUNT_DATA } from '../constants/actionTypes'
import { ACCOUNT_INITIAL_STATE } from '../constants/initialState'

export default function account (state = ACCOUNT_INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_ACCOUNT_DATA:
      return {
        ...state,
        accountData: action?.data?.data
      }
    default:
      return state
  }
}
