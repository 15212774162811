import { GENERATE_PRESIGNED_URL_API, LOGOUT_API } from '../constants/endpoints'
import axios from 'axios'
import { getCommonHeaders, hideLoader, showLoader, showToaster } from '../helper/Utils'
import { TOAST_ERROR, TOAST_SUCCESS } from '../constants/siteConstant'

const headers = getCommonHeaders()
export async function tryLoggingOutUser (data) {
  try {
    const res = await axios.post(LOGOUT_API, (data), { headers })
    showToaster({ message: (res?.data?.message || res?.message), type: TOAST_SUCCESS })
    return res.data
  } catch (error) {
    showToaster({ message: error?.response?.data?.message || (error?.message || error?.error), type: TOAST_ERROR })
  }
}

export async function fetchPresignedUrl (filename, dealerName) {
  try {
    showLoader()
    const response = await axios.post(GENERATE_PRESIGNED_URL_API, ({ filename, dealer_name: dealerName }), { headers })
    showToaster({ message: (response?.data?.message || response?.message), type: TOAST_SUCCESS })
    return response?.data
  } catch (error) {
    hideLoader()
    showToaster({ message: error?.response?.data?.message || (error?.message || error?.error), type: TOAST_ERROR })
  }
}
