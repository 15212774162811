export const SUCCESS_CODE = 200
export const TOAST_ERROR = 'Error'
export const TOAST_SUCCESS = 'Success'
export const DELAY = 10000
export const ACCOUNT = 'Account'
export const LOGOUT = 'Logout'
export const DEFAULT_PAGE_SIZE = 25
export const PER_PAGE_30 = 30
export const PER_PAGE_35 = 35
export const MAX_PARTS_DEALER = 3
export const DESCRIPTION = 'Description'
export const ADDRESS_1 = 'Address 1'
export const ADDRESS_2 = 'Address 2'
export const ACTIONS = 'Actions'
export const DMS = 'DMS'
export const VIN = 'VIN'
export const BRAND = 'Brand'
export const MODEL = 'Model'
export const COUNT_LIMIT = 10
export const DMS_ACCOUNT_ID = 'DMS Account Id'
export const DMS_WORKSHOP_ID = 'DMS Workshop Id'
export const PRIMARY_EMAIL = 'PRIMARY EMAIL'
export const PHONE = 'PHONE'
export const CUSTOMER_NO = 'CUSTOMER_NO'
export const INSURANCE = 'INSURANCE'
export const STREET = 'STREET'
export const CITY = 'CITY'
export const COUNTRY = 'COUNTRY'
export const ZIP_CODE = 'ZIP_CODE'
export const HELLOPARTS_CDN = 'http://d2lybwojpmsvj1.cloudfront.net'

export const LEAD_STATUS = {
  REGISTERED: 'REGISTERED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED'
}

export const ORDER_STATUS = {
  partially_delivered: 'partially_delivered'
}

export const PORTAL_PATH = {
  AUFTRAG: '/auftrag'
}

export const AUFTRAG_STATUS = {
  PENDING: 'PENDING',
  ARCHIVED: 'ARCHIVED'
}

export const ANALYTICS_COLUMNS = {
  orders_placed: 'orders_placed',
  total_order_value: 'total_order_value'
}

export const COL_SORT_TYPES = {
  DESC: 'DESC',
  ASC: 'ASC'
}

export const templates = {
  TEMPLATES: 'TEMPLATES',
  QUICKVIEW: 'QUICKVIEW'
}

export const orderTableValues = {
  PERCENTAGE_VALUE: 'percentage_value',
  ABSOLUTE_EURO_VALUE: 'absolute_euro_value'
}

export const AUTODAP_EVENT_NAME = 'EXTERNAL_PARTS_VALIDATION:AUTODAP'

export const AUTODAP_STATUS_MAPPING = {
  SUCCESS: { code: 200, tooltipData: 'The request was successful.' },
  PARTIAL_CONTENT: { code: 206, tooltipData: 'Partial data returned. Check for completeness.' },
  BAD_REQUEST: { code: 400, tooltipData: 'Bad request. Review the parameters sent.' },
  UNAUTHORIZED: { code: 401, tooltipData: 'Unauthorized. Verify authentication credentials.' }
}

export const ENVIRONMENTS = {
  STAGING: 'staging',
  PRE_PROD: 'pre_prod',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development'
}

export const ROLES = {
  ORG_SUPER_ADMIN: 'orgsuperadmin'
}

export const AUFTRAG_COLUMN_TYPE = {
  AUFTRAG_ID: 'AUFTRAG_ID',
  DEALER_ORDER_ID: 'DEALER_ORDER_ID'
}
