import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import mainReducer from '../reducer/index'

export default function configureStore (initialState) {
  const store = createStore(
    mainReducer,
    applyMiddleware(thunk),
    initialState
  )
  return store
}
