import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { menuItems, sidebarMenuItems } from '../../helper/sidebarConstant'
import Loader from '../common/Loader'
import { ACCOUNT, LOGOUT, ROLES } from '../../constants/siteConstant'
import { LOGIN_URL } from '../../constants/endpoints'
import { tryLoggingOutUser } from '../../services/common'
import { hideLoader, showLoader } from '../../helper/Utils'
import ConfirmationModal from '../common/Confirmation'

function Navigation ({ accountData }) {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const [openMenus, setOpenMenus] = useState({})

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu)
  }

  const handleGoToHomePage = () => {
    window.location.href = '/'
  }

  const onLogoutCTA = async () => {
    showLoader()
    await tryLoggingOutUser()
    hideLoader()
    window.location.href = LOGIN_URL
  }

  const isMenuActive = (subItems) => {
    return subItems?.some((subItem) => '/' + subItem.url === window.location.pathname)
  }

  const toggleNestedMenu = (menu) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu]
    }))
  }

  return (
    <>
      <nav className='navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg' id='navbarVertical'>
        <div className='container-fluid'>
          <button className='navbar-toggler ms-n2' type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0 vertical-center cursor-pointer'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='logo' className='logo' onClick={handleGoToHomePage} />
          </div>
          <div className='collapse navbar-collapse' id='sidebarCollapse'>
            <ul className='navbar-nav'>
              {sidebarMenuItems
                ?.filter((menu) => {
                  // Check if the menu is 'roles' and the role condition is satisfied
                  if (menu === 'roles' && accountData?.role !== ROLES.ORG_SUPER_ADMIN) {
                    return false
                  }
                  return true
                })
                ?.map((menu, index) => {
                  const menuConfig = menuItems[menu]
                  if (menuConfig?.subItems) {
                    const isActive = isMenuActive(menuConfig.subItems)
                    return (
                      <li className='nav-item' key={menuConfig?.name + index}>
                        <div
                          className={`nav-link cursor-pointer ${isActive ? 'active' : ''}`}
                          onClick={() => toggleNestedMenu(menu)}
                        >
                          {menuConfig.icon}{menuConfig.name}
                          <i
                            className={`gp-ml8 bi bi-chevron-${
                    openMenus[menu] ? 'up' : 'down'
                  }`}
                          />
                        </div>
                        {openMenus[menu] && (
                          <ul
                            className='nested-menu'
                            style={{ listStyleType: 'none', paddingLeft: '20px' }}
                          >
                            {menuConfig.subItems.map((subItem, subIndex) => (
                              <li className='nav-item' key={subItem.name + subIndex}>
                                <Link
                                  className={`nav-link submenu-text ${
                          '/' + subItem.url === window.location.pathname
                            ? 'highlight'
                            : ''
                        }`}
                                  to={subItem.url}
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    )
                  } else {
                    return (
                      <li className='nav-item' key={menuConfig?.name + index}>
                        <Link
                          className={`nav-link ${
                  '/' + menuConfig.url === window.location.pathname
                    ? 'active'
                    : ''
                }`}
                          to={menuConfig?.url}
                          onClick={() => handleMenuClick(menu)}
                        >
                          {menuConfig?.icon}
                          {menuConfig?.name}
                        </Link>
                      </li>
                    )
                  }
                })}
            </ul>

            <hr className='navbar-divider my-5 opacity-20' />
            <div className='mt-auto' />
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link
                  className={`nav-link ${
          selectedMenu === 'accounts' ||
          window.location.pathname === '/accounts'
            ? 'active'
            : ''
        }`}
                  to='accounts'
                  onClick={() => handleMenuClick('accounts')}
                >
                  <i className='bi bi-person-square' /> {ACCOUNT}
                </Link>
              </li>
              <li className='nav-item' onClick={() => setLogoutModalOpen(true)}>
                <Link className='nav-link' href='#'>
                  <i className='bi bi-box-arrow-left' /> {LOGOUT}
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>
      <div className='h-screen flex-grow-1 overflow-y-lg-auto'>
        <React.Suspense fallback={<Loader />}>
          <Outlet />
        </React.Suspense>
      </div>
      <ConfirmationModal
        show={logoutModalOpen}
        onHide={() => setLogoutModalOpen(false)}
        onConfirm={() => onLogoutCTA()}
        message='Do you want to Logout?'
        title='Logout'
        confirmBtnTxt='YES'
        cancelBtnTxt='NO'
      />
    </>
  )
}

export default Navigation
