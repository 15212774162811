import { DELETE_OE_PARTS, SHOW_CHANGE_LOG_DATA, SHOW_AUFTRAG_DATA, DELETE_COMPLETE_ORDER, SHOW_AUFTRAG_DATA_BY_ID, UPDATE_AUFTRAG_QUANTITY, UPDATE_AUFTRAG_DATA, UPDATE_PARTS_DEALER, UPDATE_VIN_PARTS_DEALER, SHOW_TIMELINE_DATA, SHOW_EVENT_DATA, UPDATE_PART_DEALER, UPDATE_STATUS, UPDATE_START_DATE, UPDATE_END_DATE, UPDATE_ORDER_TYPE } from '../constants/actionTypes'
import { AUFTRAG_INITIAL_STATE } from '../constants/initialState'

function getSelectedAuftragIndex (currentAuftrag, state) {
  if (currentAuftrag && currentAuftrag.id && state && state.auftragData?.data?.auftrags.length) {
    const index = state.auftragData?.data?.auftrags.findIndex(element => element.id === currentAuftrag.id)
    return index
  }
  return -1
}

export default function auftrag (state = AUFTRAG_INITIAL_STATE, action) {
  let updatedAuftragData, updatedOeData, filteredParts, indexToSearch, listToParse
  switch (action.type) {
    case SHOW_AUFTRAG_DATA:
      return {
        ...state,
        auftragData: action?.data
      }
    case SHOW_AUFTRAG_DATA_BY_ID:
      return {
        ...state,
        auftragOeParts: action?.data?.data,
        changeLogData: ''
      }
    case UPDATE_AUFTRAG_QUANTITY:
      updatedOeData = state?.auftragOeParts?.auftrag_oe_parts?.map(part => {
        if (part.id === action.data.data.id) {
          return { ...part, stock_requested: action.data.data.stock_requested }
        }
        return part
      })
      return {
        ...state,
        auftragOeParts: {
          ...state.auftragOeParts,
          auftrag_oe_parts: updatedOeData
        }
      }
    case DELETE_OE_PARTS:
      filteredParts = state?.auftragOeParts?.auftrag_oe_parts?.filter(part => {
        return !action.data.data.some(deletedPart => deletedPart.id === part.id)
      })
      return {
        ...state,
        auftragOeParts: {
          ...state.auftragOeParts,
          auftrag_oe_parts: filteredParts
        }
      }
    case UPDATE_PARTS_DEALER:
      return {
        ...state
      }
    case UPDATE_AUFTRAG_DATA:
      indexToSearch = getSelectedAuftragIndex(action.data, state)
      listToParse = [...state.auftragData.data.auftrags]

      if (indexToSearch >= 0 && listToParse && listToParse[indexToSearch]) {
        listToParse[indexToSearch] = action.data
      }

      return {
        ...state,
        auftragData: {
          ...state.auftragData,
          data: {
            ...state.auftragData.data,
            auftrags: listToParse
          }
        }
      }
    case UPDATE_VIN_PARTS_DEALER:
      return {
        ...state,
        auftragOeParts: action?.data?.data
      }
    case DELETE_COMPLETE_ORDER:
      updatedAuftragData = state?.auftragData?.data?.auftrags?.filter(item => item.id !== action?.id)
      return {
        ...state,
        auftragData: {
          ...state.auftragData,
          data: {
            ...state.auftragData.data,
            auftrags: updatedAuftragData
          }
        }
      }
    case SHOW_TIMELINE_DATA:
      return {
        ...state,
        eventsData: action?.data?.data
      }
    case SHOW_EVENT_DATA:
      return {
        ...state,
        eventsRequestResponseData: action?.data?.data
      }
    case SHOW_CHANGE_LOG_DATA:
      return {
        ...state,
        changeLogData: action?.data
      }
    case UPDATE_PART_DEALER:
      return {
        ...state,
        partDealer: action?.data
      }
    case UPDATE_STATUS:
      return {
        ...state,
        selectedStatus: action?.data
      }
    case UPDATE_START_DATE:
      return {
        ...state,
        startDate: action?.data
      }
    case UPDATE_END_DATE:
      return {
        ...state,
        endDate: action?.data
      }
    case UPDATE_ORDER_TYPE:
      return {
        ...state,
        orderType: action?.data
      }
    default:
      return state
  }
}
