import { FETCH_CATALOG_DATA, UPLOAD_CATALOG } from '../constants/actionTypes'
import { UPLOAD_CATALOG_INIT_STATE } from '../constants/initialState'

export default function uploadCatalog (state = UPLOAD_CATALOG_INIT_STATE, action) {
  switch (action.type) {
    case FETCH_CATALOG_DATA:
      return {
        ...state,
        catalogData: action?.data?.data
      }
    case UPLOAD_CATALOG:
      return {
        ...state,
        catalogData: [{ ...action?.data?.data }, ...state.catalogData]
      }
    default:
      return state
  }
}
