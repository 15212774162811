import { SHOW_ACCOUNT_DATA } from '../constants/actionTypes'
import { ACCOUNT_DETAILS_API } from '../constants/endpoints'
import { hideLoader, showLoader } from '../helper/Utils'
import { getData } from '../helper/apiCall'

export function showAccountDetails (data) {
  return { type: SHOW_ACCOUNT_DATA, data }
}

export function tryFetchingAccountData () {
  return function (dispatch) {
    showLoader()
    getData(ACCOUNT_DETAILS_API)
      .then(response => {
        hideLoader()
        dispatch(showAccountDetails(response))
      })
  }
}
