export const SHOW_WORKSHOP_ACCOUNT_DATA = 'SHOW_WORKSHOP_ACCOUNT_DATA'
export const UPDATE_WORKSHOP_ACCOUNT = 'UPDATE_WORKSHOP_ACCOUNT'
export const SHOW_ACCOUNT_DATA = 'SHOW_ACCOUNT_DATA'
export const SHOW_ERROR = 'SHOW_ERROR'
export const SHOW_AUFTRAG_DATA = 'SHOW_AUFTRAG_DATA'
export const UPDATE_WORKSHOP_BY_ID_DATA = 'UPDATE_WORKSHOP_BY_ID_DATA'
export const UPDATE_WORKSHOP_ACCOUNT_DETAILS = 'UPDATE_WORKSHOP_ACCOUNT_DETAILS'
export const WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS = 'WORKSHOP_ACCOUNT_CREATION_IN_PROGRESS'
export const WORKSHOP_ACCOUNT_CREATED = 'WORKSHOP_ACCOUNT_CREATED'
export const WORKSHOP_CREATED_FOR_ACCOUNT = 'WORKSHOP_CREATED_FOR_ACCOUNT'
export const WORKSHOP_ACCOUNT_CREATION_FAILED = 'WORKSHOP_ACCOUNT_CREATION_FAILED'
export const UPDATE_ALL_DMS = 'UPDATE_ALL_DMS'
export const UPDATE_WORKSHOP_USERS_DATA = 'UPDATE_WORKSHOP_USERS_DATA'
export const WORKSHOP_UPDATE_IN_PROGRESS = 'WORKSHOP_UPDATE_IN_PROGRESS'
export const WORKSHOP_UPDATE_COMPLETED = 'WORKSHOP_UPDATE_COMPLETED'
export const UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS = 'UPDATE_WORKSHOP_ADDRESS_IN_PROGRESS'
export const UPDATE_WORKSHOP_ADDRESS_COMPLETED = 'UPDATE_WORKSHOP_ADDRESS_COMPLETED'
export const UPDATE_PART_DEALER_IN_PROGRESS = 'UPDATE_PART_DEALER_IN_PROGRESS'
export const UPDATE_PART_DEALER_COMPLETED = 'UPDATE_PART_DEALER_COMPLETED'
export const UPDATE_SELECTED_ACCOUNT = 'UPDATE_SELECTED_ACCOUNT'
export const WORKSHOP_USER_ADDED = 'WORKSHOP_USER_ADDED'
export const UPDATE_WORKSHOP_USER = 'UPDATE_WORKSHOP_USER'
export const SHOW_AUFTRAG_DATA_BY_ID = 'SHOW_AUFTRAG_DATA_BY_ID'
export const UPDATE_AUFTRAG_QUANTITY = 'UPDATE_AUFTRAG_QUANTITY'
export const DELETE_OE_PARTS = 'DELETE_OE_PARTS'
export const UPDATE_PARTS_DEALER = 'UPDATE_PARTS_DEALER'
export const UPDATE_VIN_PARTS_DEALER = 'UPDATE_VIN_PARTS_DEALER'
export const DELETE_COMPLETE_ORDER = 'DELETE_COMPLETE_ORDER'
export const TOGGLE_PARTS_DEALER_STATUS = 'TOGGLE_PARTS_DEALER_STATUS'
export const SHOW_CONTACT_INFO_DATA = 'SHOW_CONTACT_INFO_DATA'

export const SHOW_ALL_AUFTRAG_DATA = 'SHOW_ALL_AUFTRAG_DATA'
export const SINGLE_AUFTRAG_DATA = 'SINGLE_AUFTRAG_DATA'
export const FETCHING_AUFTRAG_DATA = 'FETCHING_AUFTRAG_DATA'
export const SORT_BY_DATE = 'SORT_BY_DATE'
export const SHOW_AUFTRAG_LOGS = 'SHOW_AUFTRAG_LOGS'
export const CREATE_ORDER = 'CREATE_ORDER'
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE'
export const UPDATE_VALIDATION_DATA = 'UPDATE_VALIDATION_DATA'
export const UPDATE_STOCK_DATA = 'UPDATE_STOCK_DATA'
export const UPDATE_ORDER_DATA = 'UPDATE_ORDER_DATA'
export const UPLOAD_PDF_FILE = 'UPLOAD_PDF_FILE'
export const UPLOADING_IN_PROGRESS = 'UPLOADING_IN_PROGRESS'
export const START_OCR_ANALYSIS = 'START_OCR_ANALYSIS'
export const START_UPDATING_DATA = 'START_UPDATING_DATA'
export const UPDATED_DATA = 'UPDATED_DATA'
export const SHOW_PROFILE_DATA = 'SHOW_PROFILE_DATA'
export const UPDATE_PART_DEALER = 'UPDATE_PART_DEALER'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_START_DATE = 'UPDATE_START_DATE'
export const UPDATE_END_DATE = 'UPDATE_END_DATE'
export const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE'

export const SET_ANALYTICS_WORKSHOP_DATA = 'SET_ANALYTICS_WORKSHOP_DATA'
export const SET_ANALYTICS_DEALERS_DATA = 'SET_ANALYTICS_DEALERS_DATA'
export const SET_ANALYTICS_BRAND_DATA = 'SET_ANALYTICS_BRAND_DATA'
export const SHOW_TIMELINE_DATA = 'SHOW_TIMELINE_DATA'
export const SHOW_EVENT_DATA = 'SHOW_EVENT_DATA'
export const SHOW_CHANGE_LOG_DATA = 'SHOW_CHANGE_LOG_DATA'
export const SET_WORKSHOPS_ACCOUNTS_LIST = 'SET_WORKSHOPS_ACCOUNTS_LIST'
export const UPDATE_BRAND_LIST = 'UPDATE_BRAND_LIST'
export const UPDATE_BRAND_GROUP_LIST = 'UPDATE_BRAND_GROUP_LIST'
export const UPDATE_WS_GROUP_LIST = 'UPDATE_WS_GROUP_LIST'
export const ADD_NEW_BRAND_GROUP_TO_LIST = 'ADD_NEW_BRAND_GROUP_TO_LIST'
export const UPDATE_WS_PERMISSION_LIST = 'UPDATE_WS_PERMISSION_LIST'
export const ADD_NEW_PERMISSION_TO_LIST = 'ADD_NEW_PERMISSION_TO_LIST'
export const ADD_NEW_WS_GROUP_TO_LIST = 'ADD_NEW_WS_GROUP_TO_LIST'
export const DELETE_BRAND_GROUP_FROM_LIST = 'DELETE_BRAND_GROUP_FROM_LIST'
export const DELETE_WS_GROUP_FROM_LIST = 'DELETE_WS_GROUP_FROM_LIST'
export const UPDATE_CURRENT_WS = 'UPDATE_CURRENT_WS'
export const UPDATE_CURRENT_BRAND_GROUP = 'UPDATE_CURRENT_BRAND_GROUP'
export const UPDATE_WS_MODES_LIST = 'UPDATE_WS_MODES_LIST'
export const SET_WORKSHOPS_LIST = 'SET_WORKSHOPS_LIST'
export const UPDATE_BRANDS = 'UPDATE_BRANDS'
export const DELETE_PERMISSION_FROM_LIST = 'DELETE_PERMISSION_FROM_LIST'
export const UPDATE_CURRENT_PERMISSION_DATA = 'UPDATE_CURRENT_PERMISSION_DATA'

export const UPDATE_AUFTRAG_DATA = 'UPDATE_AUFTRAG_DATA'
export const ADMIN_USER_PREFERENCES = 'ADMIN_USER_PREFERENCES'
export const FETCH_ADMIN_PREFERENCES = 'FETCH_ADMIN_PREFERENCES'
export const UPDATE_DMS_IDS = 'UPDATE_DMS_IDS'

export const UPLOAD_CATALOG = 'UPLOAD_CATALOG'
export const FETCH_CATALOG_DATA = 'FETCH_CATALOG_DATA'
export const SHOW_BRANDS_ASSOCIATION = 'SHOW_BRANDS_ASSOCIATION'
export const ADD_BRAND_TO_PART_DEALER = 'ADD_BRAND_TO_PART_DEALER'
export const DELETE_BRANDS = 'DELETE_BRANDS'

export const SHOW_ALL_ROLES = 'SHOW_ALL_ROLES'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const ADD_WORKSHOP_ACCOUNT_WITH_GROUP = 'ADD_WORKSHOP_ACCOUNT_WITH_GROUP'
export const REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP = 'REMOVE_WORKSHOP_ACCOUNT_WITH_GROUP'
