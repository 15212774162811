import { SHOW_CONTACT_INFO_DATA } from '../constants/actionTypes'
import { GET_CONTACT_INFO_API } from '../constants/endpoints'
import { hideLoader, showLoader } from '../helper/Utils'
import { getData } from '../helper/apiCall'

export function showContactDetails (data) {
  return { type: SHOW_CONTACT_INFO_DATA, data }
}

export function tryFetchingContactInfoData (page, pageSize, sortBy, sortOrder, searchQuery) {
  return function (dispatch) {
    showLoader()
    const queryParams = { page, pageSize }

    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }

    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${GET_CONTACT_INFO_API}?${queryString}`
      : GET_CONTACT_INFO_API

    getData(endpointWithQuery)
      .then(response => {
        hideLoader()
        dispatch(showContactDetails(response))
      })
  }
}
