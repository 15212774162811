import { SHOW_BRANDS_ASSOCIATION, ADD_BRAND_TO_PART_DEALER, DELETE_BRANDS, UPDATE_BRANDS } from '../constants/actionTypes'
import { FETCH_BRANDS_ASSOCIATION_API, BRAND_PART_DEALER_API } from '../constants/endpoints'
import { SUCCESS_CODE, TOAST_ERROR } from '../constants/siteConstant'
import { hideLoader, showLoader, showToaster } from '../helper/Utils'
import { getData, patchData, postData } from '../helper/apiCall'

export function showBrandsAssociationData (data) {
  return { type: SHOW_BRANDS_ASSOCIATION, data }
}

export function addBrandToPartDealer (data) {
  return { type: ADD_BRAND_TO_PART_DEALER, data }
}

export function deleteBrands (data) {
  return { type: DELETE_BRANDS, data }
}

export function updateBrands (data) {
  return { type: UPDATE_BRANDS, data }
}

export function tryFetchingBrandsAssociationData (pageNo, pageSize, sortBy, sortOrder, searchQuery, selectedPartDealer, selectedStatus, orderType) {
  return function (dispatch) {
    showLoader()

    const queryParams = { page: pageNo, pageSize }
    if (sortBy && sortOrder) {
      queryParams.sortBy = sortBy
      queryParams.sortOrder = sortOrder
    }

    if (searchQuery) {
      queryParams.searchQuery = searchQuery
    }

    if (selectedPartDealer && selectedPartDealer !== 'Select Part Dealer') {
      queryParams.part_dealer = selectedPartDealer
    }

    if (selectedStatus === 'Linked') {
      queryParams.showDeleted = false
    }
    if (selectedStatus === 'Deleted') {
      queryParams.showDeleted = true
    }

    if (orderType) {
      queryParams.insurance = orderType
    }

    const queryString = queryParams && Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&')

    const endpointWithQuery = queryString
      ? `${FETCH_BRANDS_ASSOCIATION_API}?${queryString}`
      : FETCH_BRANDS_ASSOCIATION_API

    getData(endpointWithQuery)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(showBrandsAssociationData(response))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryAddingBrandToPartDealer (data) {
  return function (dispatch) {
    showLoader()
    postData(`${BRAND_PART_DEALER_API}`, data)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(addBrandToPartDealer(response))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryDeletingBrands (id) {
  return function (dispatch) {
    showLoader()
    patchData(`${BRAND_PART_DEALER_API}/delete/${id}`)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(deleteBrands(id))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}

export function tryUpdatingBrandsAssociation (id, data) {
  return function (dispatch) {
    showLoader()
    patchData(`${BRAND_PART_DEALER_API}/${id}`, data)
      .then(response => {
        if (response.code === SUCCESS_CODE) {
          hideLoader()
          dispatch(updateBrands({ id, response }))
        } else {
          hideLoader()
          showToaster({ message: (response?.message || response?.error), type: TOAST_ERROR })
        }
      })
  }
}
