import { DASHBOARD_INIT_STATE } from '../constants/initialState'
import {
  FETCHING_AUFTRAG_DATA,
  SHOW_ALL_AUFTRAG_DATA,
  SINGLE_AUFTRAG_DATA,
  UPDATE_VALIDATION_DATA,
  SHOW_ERROR,
  SHOW_AUFTRAG_LOGS,
  CREATE_ORDER,
  UPDATE_STOCK_DATA,
  UPDATE_ORDER_DATA,
  LOGOUT_COMPLETE,
  UPLOADING_IN_PROGRESS,
  START_OCR_ANALYSIS,
  START_UPDATING_DATA,
  UPDATED_DATA,
  SHOW_PROFILE_DATA,
  SET_ANALYTICS_WORKSHOP_DATA,
  SET_ANALYTICS_DEALERS_DATA,
  SET_ANALYTICS_BRAND_DATA,
  SET_WORKSHOPS_ACCOUNTS_LIST
} from '../constants/actionTypes'
import { SUCCESS_CODE } from '../constants/siteConstant'

export default function dashboardReducer (state = DASHBOARD_INIT_STATE, action) {
  switch (action.type) {
    case FETCHING_AUFTRAG_DATA:
      return {
        ...state,
        isFetching: true,
        isError: { isError: false, errorMsg: '', type: '' },
        isLogout: false,
        uploadingInProgress: false,
        apiResponse: { code: '', message: '', type: '' }
      }
    case SHOW_ALL_AUFTRAG_DATA:
      return {
        ...state,
        isFetching: false,
        allAuftragData: { list: action?.data?.data, totalCount: action?.data?.count }
      }

    case SINGLE_AUFTRAG_DATA:
      return {
        ...state,
        currentAuftragData: action?.data?.data
      }
    case SHOW_AUFTRAG_LOGS:
      return {
        ...state,
        isAuftragLogs: true,
        auftragLogs: action?.data?.data
      }
    case CREATE_ORDER:
      return {
        ...state,
        apiResponse: { code: action?.data?.code, message: 'Your order has been successsfully created !', type: 'success' },
        createOrderResponse: action?.data?.data
      }
    case LOGOUT_COMPLETE:
      return {
        ...state,
        isLogout: true
      }
    case UPDATE_VALIDATION_DATA:
      return {
        ...state,
        validationData: action.data
      }
    case UPDATE_STOCK_DATA:
      return {
        ...state,
        stockData: action.data
      }
    case UPDATE_ORDER_DATA:
      return {
        ...state,
        orderData: action.data
      }
    case UPLOADING_IN_PROGRESS:
      return {
        ...state,
        uploadingInProgress: true,
        pdfUrl: '',
        apiResponse: { code: '', message: '', type: '' },
        isError: { isError: false, errorMsg: '', type: '' }
      }
    case START_OCR_ANALYSIS:
      return {
        ...state,
        apiResponse: { code: SUCCESS_CODE, message: 'Please wait! We are analyzing your document.', type: 'success' },
        ocrResonseData: action?.data?.data
      }
    case START_UPDATING_DATA:
      return {
        ...state,
        isUpdatingData: true,
        apiResponse: { code: '', message: 'Your data has been successfully updated!', type: 'success' }
      }
    case UPDATED_DATA:
      return {
        ...state,
        isUpdatingData: false,
        apiResponse: { code: SUCCESS_CODE, message: 'Your data has been successfully updated!', type: 'success' },
        currentAuftragData: action?.data?.data
      }
    case SHOW_ERROR:
      return {
        ...state,
        isError: { isError: true, errorMsg: action?.error, type: 'error' },
        uploadingInProgress: false
      }

    case SHOW_PROFILE_DATA:
      return {
        ...state,
        profileData: action?.data?.data
      }

    case SET_ANALYTICS_WORKSHOP_DATA:
      return {
        ...state,
        analyticsWorkshopData: action?.data?.data
      }

    case SET_ANALYTICS_DEALERS_DATA:
      return {
        ...state,
        analyticsDealerData: action?.data?.data
      }

    case SET_ANALYTICS_BRAND_DATA:
      return {
        ...state,
        analyticsBrandData: action?.data?.data
      }

    case SET_WORKSHOPS_ACCOUNTS_LIST:
      return {
        ...state,
        workshopAccountsList: action?.data?.data
      }

    default:
      return state
  }
}
