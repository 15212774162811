import { ADD_BRAND_TO_PART_DEALER, DELETE_BRANDS, SHOW_BRANDS_ASSOCIATION, UPDATE_BRANDS } from '../constants/actionTypes'
import { BRANDS_ASSOCIATION_STATE } from '../constants/initialState'

export default function brandsAssociation (state = BRANDS_ASSOCIATION_STATE, action) {
  switch (action.type) {
    case SHOW_BRANDS_ASSOCIATION:
      return {
        ...state,
        brandsAssociationData: action?.data?.data
      }
    case ADD_BRAND_TO_PART_DEALER:
      return {
        ...state,
        brandsAssociationData: {
          ...state.brandsAssociationData,
          brandPartDealerAssoc: [
            action?.data?.data,
            ...state.brandsAssociationData.brandPartDealerAssoc
          ]
        }
      }
    case DELETE_BRANDS:
      return {
        ...state,
        brandsAssociationData: {
          ...state.brandsAssociationData,
          brandPartDealerAssoc: state.brandsAssociationData.brandPartDealerAssoc.map(brandPartDealer =>
            brandPartDealer.id === action?.data ? { ...brandPartDealer, deletedAt: Date.now() } : brandPartDealer
          )
        }
      }
    case UPDATE_BRANDS:
      return {
        ...state,
        brandsAssociationData: {
          ...state.brandsAssociationData,
          brandPartDealerAssoc: state.brandsAssociationData.brandPartDealerAssoc.map(brandPartDealer => (
            brandPartDealer.id === action?.data?.id ? { ...brandPartDealer.response, ...action?.data?.response?.data } : brandPartDealer
          ))
        }
      }
    default:
      return state
  }
}
